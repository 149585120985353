import phoneAndCard from './phoneAndCard.png';
import appleIcon from './apple-icon.png';
import androidIcon from './android-icon.png';
import './LandingPage.css';
import QRCode from 'react-qr-code';
import useMobileDetect from 'use-mobile-detect-hook';
import queryString from 'query-string';

function App() {
	const pathItems = window.location.pathname.split('/').filter(i => !!i);
	const slug = pathItems[0]; //just using the first part of the path for now
	const queryParams = queryString.parse(window.location.search);

	//if we ever use this project for stuff other than affiliate links, this needs to be smarter
	if (slug) queryParams.a = slug;

	const forwardedParams = queryString.stringify(queryParams);
	let destination = `${process.env.REACT_APP_BRANCH_LINK_BASE_URL}/`;
	if (forwardedParams) {
		destination = `${process.env.REACT_APP_BRANCH_LINK_BASE_URL}/?${forwardedParams}`;
	}

	const detectMobile = useMobileDetect();
	const isMobile = detectMobile.isMobile();
	const type = isMobile ? 'mobile' : 'desktop';

	const renderDescriptionArea = () => {
		if (!isMobile) {
			return (
				<div className="block">
					<div className="description-container">
						{renderDescriptionContent()}
						{renderLogoArea()}
					</div>
				</div>
			);
		}

		return renderDescriptionContent();
	}

	const renderDescriptionContent = () => {
		return (
			<div>
				<h1 className="landing-headline">
					Download the<br />Manifest App
				</h1>
				<div className="landing-description">
					{
						!isMobile && <p className="description">Scan the code to get Manifest.</p>
					}
					<p className="description">Business banking for creators -</p>
					<p className="description">available on iOS and Android.</p>
				</div>
			</div>
		)
	};

	const renderLogoArea = () => {
		return (
			<div className={`${type} logo-container`}>
				<div className="logo">
					<img src={appleIcon} alt="apple logo" />
					<span>iOS</span>
				</div>
				<div className="logo">
					<img src={androidIcon} alt="android logo" />
					<span>Android</span>
				</div>
			</div>
		)
	};

	const renderImageArea = () => {
		if (!isMobile) {
			return (
				<div className="block">
					<div className="main-image-container">
						<img src={phoneAndCard} className="landing-logo" alt="logo" />
					</div>
				</div>
			);
		}

		return <img src={phoneAndCard} className="landing-logo" alt="logo" />;
	}

	const renderQRCodeArea = () => {
		return (
			<div className="block">
				<div className="qr-code-container">
					<QRCode
						value={destination}
						size={200}
						fgColor="black"
						bgColor="white"
						style={{padding: '32px'}}
					/>
				</div>
			</div>
		);
	}

	const handleGetAppClick = () => {
		window.location = destination;
	};

	if (detectMobile.isMobile()) {
		return (
			<div className="mobile landing-page">
					{renderDescriptionArea()}
					{renderImageArea()}
					<div className="mobile-bottom-section">
						<button className="get-app-button" onClick={handleGetAppClick}>
							Get the App
						</button>
						{renderLogoArea()}
					</div>
			</div>
		);
	}

	return (
		<div className="desktop landing-page">
			<div className="desktop pill">
				{renderImageArea()}
				{renderDescriptionArea()}
				{renderQRCodeArea()}
			</div>
		</div>
	);
}

export default App;
